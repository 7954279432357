import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { gql, useQuery } from '../../../components/ApolloClient';
import { locationForModal, ModalRoute } from '../../../components/ModalRoute';
import {
  EnterpriseContractDiscountSelectionModal,
  EnterpriseContractModalFirst,
  EnterpriseContractProductBasedDiscountModal,
  ContractDiscountTypeSelectionModal,
} from '../../../components/EnterpriseContractModalFirst';
import { EnterpriseContractModalSecond } from '../../../components/EnterpriseContractModalSecond';
import { PartnerContractList } from './list';
import { ContractType } from './utils';
import { EnterpriseContractCancelModal } from '../../../components/EnterpriseContractModalCancel';
import { ENTERPRISE_CONTRACT_PRODUCT_BASED_DISCOUNTS_ENABLED } from '../../../config';

const ENTERPRISE_CONTRACT_MODAL_CHOOSE_DISCOUNT_TYPE_MODAL =
  'enterprise_contract_modal_choose_type_modal';

const ENTERPRISE_CONTRACT_DISCOUNT_PERCENTAGES_MODAL =
  'enterprise_contract_discount_percentages_modal';

export const ENTERPRISE_CONTRACT_PRODUCT_DISCOUNT_BASED_MODAL =
  'enterprise_contract_product_discount_based_modal';

export const ENTERPRISE_CONTRACT_FIRST_MODAL =
  'enterprise_contract_first_modal';

const ENTERPRISE_CONTRACT_SECOND_MODAL = 'enterprise_contract_second_modal';

export const ENTERPRISE_CONTRACT_CANCEL_MODAL =
  'enterprise_contract_cancel_modal';

export const OnEnterpriseContractCancelModal = ({
  partnerId,
  session,
  reloadContracts,
}: {
  partnerId: string;
  session: { token: string };
  reloadContracts: () => Promise<any>;
}) => {
  return (
    <ModalRoute
      modalName={ENTERPRISE_CONTRACT_CANCEL_MODAL}
      render={({ history, location, modalParams }) => (
        <EnterpriseContractCancelModal
          onDismiss={() =>
            history.replace(
              locationForModal({
                location,
                modal: undefined,
              })
            )
          }
          session={session}
          location={location}
          history={history}
          partnerId={partnerId}
          onContinue={() =>
            reloadContracts().then(() => {
              history.replace(
                locationForModal({
                  location,
                  modal: undefined,
                })
              );
            })
          }
          modalParams={{
            ...modalParams,
          }}
        />
      )}
    />
  );
};

const onDismissPartnerContractModal = ({
  history,
  location,
}: {
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
}) =>
  history.replace(
    locationForModal({
      location,
      modal: undefined,
    })
  );

export const NewContractModal = ({
  partnerId,
  currency,
  session,
  onSuccess,
}: {
  partnerId: string;
  currency: string;
  session: { token: string };
  onSuccess: () => Promise<any>;
}) => {
  return (
    <>
      <ModalRoute
        modalName={ENTERPRISE_CONTRACT_MODAL_CHOOSE_DISCOUNT_TYPE_MODAL}
        render={({ history, location, modalParams }) => (
          <ContractDiscountTypeSelectionModal
            history={history}
            location={location}
            modalParams={modalParams}
            onContinue={({ nextStep }) => {
              history.replace(
                locationForModal({
                  location,
                  modal: {
                    ...modalParams,
                    modalName:
                      nextStep === 'product-based'
                        ? ENTERPRISE_CONTRACT_PRODUCT_DISCOUNT_BASED_MODAL
                        : ENTERPRISE_CONTRACT_FIRST_MODAL,
                  },
                })
              );
            }}
            onDismiss={() =>
              onDismissPartnerContractModal({ history, location })
            }
          />
        )}
      />

      <ModalRoute
        modalName={ENTERPRISE_CONTRACT_PRODUCT_DISCOUNT_BASED_MODAL}
        render={({ history, location, modalParams }) => (
          <EnterpriseContractProductBasedDiscountModal
            partnerId={partnerId}
            currency={currency}
            session={session}
            history={history}
            location={location}
            modalParams={modalParams}
            onContinue={() => {
              history.replace(
                locationForModal({
                  location,
                  modal: {
                    ...modalParams,

                    modalName: ENTERPRISE_CONTRACT_DISCOUNT_PERCENTAGES_MODAL,
                  },
                })
              );
            }}
            onDismiss={() =>
              onDismissPartnerContractModal({ history, location })
            }
          />
        )}
      />

      <ModalRoute
        modalName={ENTERPRISE_CONTRACT_DISCOUNT_PERCENTAGES_MODAL}
        render={({ history, location, modalParams }) => (
          <EnterpriseContractDiscountSelectionModal
            partnerId={partnerId}
            currency={currency}
            session={session}
            history={history}
            location={location}
            modalParams={modalParams}
            onContinue={() => {
              history.replace(
                locationForModal({
                  location,
                  modal: {
                    ...modalParams,

                    modalName: ENTERPRISE_CONTRACT_SECOND_MODAL,
                  },
                })
              );
            }}
            onDismiss={() =>
              onDismissPartnerContractModal({ history, location })
            }
          />
        )}
      />

      <ModalRoute
        modalName={ENTERPRISE_CONTRACT_FIRST_MODAL}
        render={({ history, location, modalParams }) => (
          <EnterpriseContractModalFirst
            session={session}
            location={location}
            history={history}
            partnerId={partnerId}
            currency={currency}
            modalParams={modalParams}
            onContinue={() =>
              history.push(
                locationForModal({
                  location,
                  modal: {
                    ...modalParams,
                    modalName: ENTERPRISE_CONTRACT_SECOND_MODAL,
                  },
                })
              )
            }
            onDismiss={() =>
              onDismissPartnerContractModal({ history, location })
            }
          />
        )}
      />

      <ModalRoute
        modalName={ENTERPRISE_CONTRACT_SECOND_MODAL}
        render={({ history, location, modalParams }) => (
          <EnterpriseContractModalSecond
            session={session}
            location={location}
            history={history}
            partnerId={partnerId}
            currency={currency}
            modalParams={modalParams}
            onDismiss={() =>
              onDismissPartnerContractModal({ history, location })
            }
            onContinue={() => {
              onSuccess().then(() =>
                history.replace(
                  locationForModal({
                    location,
                    modal: undefined,
                  })
                )
              );
            }}
          />
        )}
      />
    </>
  );
};

export function EnterpriseContractSection({
  session,
  partner,
  location,
}: {
  session: { token: string };
  partner: { name: string; uid: string; currency: string };
  location;
}) {
  const partnerContractsQuery = useQuery<{
    partnerById: {
      contracts: { edges: ContractType[] };
    };
  }>(
    gql`
      query PartnerContractsListQueryForAdmin($partnerId: UUID!) {
        partnerById(uid: $partnerId) {
          contracts {
            edges {
              id
              type
              commitmentCreditsCents
              discountPercentage
              commissionPercentage
              startAt
              expiresAt
              durationInMonths
              status
              remainingTime(unit: "months")
              pdfUrl
              subscriptionPlan {
                id
                displayName
              }
              subscriptionPlanDiscountPercentage
              productDiscount {
                aiMagic
                capture
                editingJobs
                subscription
              }
            }
          }
        }
      }
    `,
    { variables: { partnerId: partner.uid } }
  );

  const contracts =
    partnerContractsQuery.data?.partnerById.contracts.edges ?? [];

  return (
    <>
      <div className="d-flex justify-content-end align-items-center mb-3 mt-3">
        <Link
          className="btn btn-dark btn-sm mr-3"
          to={locationForModal({
            location,
            modal: {
              modalName:
                ENTERPRISE_CONTRACT_PRODUCT_BASED_DISCOUNTS_ENABLED === true
                  ? ENTERPRISE_CONTRACT_MODAL_CHOOSE_DISCOUNT_TYPE_MODAL
                  : ENTERPRISE_CONTRACT_FIRST_MODAL,
            },
          })}
        >
          Create contract
        </Link>
      </div>

      <PartnerContractList
        contractList={contracts}
        location={location}
        currency={partner.currency}
      />

      <NewContractModal
        partnerId={partner.uid}
        currency={partner.currency}
        session={session}
        onSuccess={partnerContractsQuery.refetch}
      />

      <OnEnterpriseContractCancelModal
        partnerId={partner.uid}
        session={session}
        reloadContracts={partnerContractsQuery.refetch}
      />
    </>
  );
}
