import React from 'react';
import produce from 'immer';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import InView from 'react-intersection-observer';
import Component2 from '@reach/component-component';
import AsyncSelect from 'react-select/async';

import BaseLayout from '../../components/BaseLayout';
import { LoadingSpinnerCentered } from '../../components/LoadingSpinner';
import { gql, useMutation, useQuery } from '../../components/ApolloClient';
import { COLOR_PALLETTE } from '../../constants';
import { STANDARD_BORDER } from '../editing-jobs/components';
import { Tooltip } from '../../components/Tooltip';
import { Modal } from '../../components/Modal';
import { KeyboardControlEvents } from '../../components/KeyboardControlEvents';
import { ImageLoadIndicator } from '../bookings/components/ImageLoadIndicator';
import { apiFetch } from '../../utils';

type IGalleryItem = {
  uid: string;
  productUrl: string;
  inspirationConceptUrl: string;
};

function CustomGalleryModal({
  selectedItemId,
  items,
  onItemIdChange,
}: {
  selectedItemId: string | undefined;
  items: IGalleryItem[];
  onItemIdChange: (props: { itemId: string | undefined }) => void;
}) {
  if (items == null || items.length === 0 || selectedItemId == null) {
    return null;
  }

  const currentItemIndex = items.findIndex(
    (item) => item.uid === selectedItemId
  );

  const prevItemIndex =
    currentItemIndex <= 0 ? items.length - 1 : currentItemIndex - 1;

  const nextItemIndex =
    currentItemIndex >= items.length - 1 ? 0 : currentItemIndex + 1;

  return (
    <Modal>
      <Component2
        didMount={() => {
          if (currentItemIndex !== -1) return;

          onItemIdChange({ itemId: undefined });
        }}
      />

      <div className="modal__overlay">
        <button
          className="modal__overlay-action"
          onClick={() => onItemIdChange({ itemId: undefined })}
        />

        <div
          className="item__list"
          style={{
            width: `${items.length * 100}vw`,
            transform: `translateX(-${currentItemIndex * 100}vw)`,
          }}
        >
          {items.map((item, itemIndex) => (
            <div key={item.uid} className="item">
              {(itemIndex === currentItemIndex ||
                itemIndex === nextItemIndex ||
                itemIndex === prevItemIndex) && (
                <ImageLoadIndicator src={item.inspirationConceptUrl}>
                  {({ loaded }) => (
                    <React.Fragment>
                      {!loaded && (
                        <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <i className="fa fa-spinner fa-spin fa-2x fa-fw text-light" />
                        </div>
                      )}

                      <div className="item__img-container">
                        <img
                          className="item__img"
                          style={{ opacity: loaded ? 1 : 0 }}
                          height="auto"
                          src={item.inspirationConceptUrl}
                          alt="generation"
                        />

                        <img
                          className="item__img item__product-img"
                          style={{ opacity: loaded ? 1 : 0 }}
                          height="auto"
                          src={item.productUrl}
                          alt="product"
                        />
                      </div>

                      <div className="item__img-container">
                        <img
                          className="item__img"
                          style={{ opacity: loaded ? 1 : 0 }}
                          height="auto"
                          src={item.inspirationConceptUrl}
                          alt="generation"
                        />
                      </div>
                    </React.Fragment>
                  )}
                </ImageLoadIndicator>
              )}
            </div>
          ))}
        </div>
      </div>

      <KeyboardControlEvents
        left={() => onItemIdChange({ itemId: items[prevItemIndex].uid })}
        right={() => onItemIdChange({ itemId: items[nextItemIndex].uid })}
        esc={() => onItemIdChange({ itemId: undefined })}
      />

      <style jsx>{`
        .modal__overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          z-index: 100;
        }

        .modal__overlay-action {
          background-color: rgba(0, 0, 0, 0.5);
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: none;
          width: 100%;
          z-index: -1;
        }

        .item__list {
          height: 100%;
          display: flex;
          transition: transform 0.3s;
          pointer-events: none;
        }

        .item {
          position: relative;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          height: 100%;
          width: 100vw;
        }

        .item__img-container {
          position: relative;
          height: auto;
          max-width: 90%;
          max-height: 80vh;
        }

        .item__img {
          object-fit: contain;
          pointer-events: auto;
          transition: opacity 1s;
        }

        .item__product-img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          transform: translate(0, 5%) scale(45%);
        }
      `}</style>
    </Modal>
  );
}

function JudgeGalleryRoute({ session }: { session: { token: string } }) {
  const history = useHistory();
  const location = useLocation();

  const [selectedPartnerIds, setSelectedPartnerIds] = React.useState<string[]>(
    []
  );

  const VISIBILITY_FILTER_OPTIONS = {
    VISIBLE: 'Visible',
    HIDDEN: 'Hidden',
  };

  const selectedItemId = React.useMemo(
    () => qs.parse(location.search).selectedItemId as string | undefined,
    [location.search]
  );

  const visibilityFilter = React.useMemo(
    () =>
      qs.parse(location.search).visibilityFilter as
        | keyof typeof VISIBILITY_FILTER_OPTIONS
        | undefined,
    [location.search]
  );

  const [
    banProductReferenceImage,
    banProductReferenceImageMutation,
  ] = useMutation<
    {
      ecommerceProductReferenceImageAddOrRemoveFromInspirationGenerationAsAdmin: {
        success: boolean;
        productReferenceMedia: {
          id: string;
          ignoredFromInspirationGeneration?: boolean;
        };
      };
    },
    {
      productReferenceImageId: string;
    }
  >(gql`
    mutation BanProductReferenceImageAsAdminFromJudgeGallery(
      $productReferenceImageId: ID!
    ) {
      ecommerceProductReferenceImageAddOrRemoveFromInspirationGenerationAsAdmin(
        input: { productReferenceImageId: $productReferenceImageId }
      ) {
        success
        productReferenceMedia {
          id
          ignoredFromInspirationGeneration
        }
      }
    }
  `);

  const ecommerceInspirationConceptQuery = useQuery<
    {
      ecommerceInspirationConceptGenerationListFromAdmin: {
        cursor: string;
        edges: {
          id: string;
          concepts?: {
            id: string;
            archivedAt: string;
            isAIPicked: boolean;
            productReferenceMedia: {
              id: string;
              ignoredFromInspirationGeneration?: boolean;
              processedImage: {
                id: string;
                image: {
                  id: string;
                  medium: {
                    url: string;
                  };
                };
              };
            };
            mediaGeneration: {
              id: string;
              media: {
                uid: string;
                medium: {
                  url: string;
                };
              };
            };
          }[];
        }[];
      };
    },
    {
      first: number;
      cursor?: string;
      ecommercePartnerIds?: string[] | null;
      visibilityFilter?: 'VISIBLE' | 'HIDDEN';
    }
  >(
    gql`
      query EcommerceInspirationConceptGenerationListFromAdmin(
        $first: Int
        $cursor: String
        $ecommercePartnerIds: [ID!]
        $visibilityFilter: String
      ) {
        ecommerceInspirationConceptGenerationListFromAdmin(
          first: $first
          cursor: $cursor
          ecommercePartnerIds: $ecommercePartnerIds
          visibilityFilter: $visibilityFilter
        ) {
          cursor
          edges {
            id
            concepts {
              id
              archivedAt
              isAIPicked
              productReferenceMedia {
                id
                ignoredFromInspirationGeneration
                processedImage {
                  id
                  image {
                    id
                    medium {
                      url
                    }
                  }
                }
              }
              mediaGeneration {
                id
                media {
                  uid
                  medium {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        first: 20,
        ecommercePartnerIds:
          selectedPartnerIds.length > 0 ? selectedPartnerIds : null,
        visibilityFilter,
      },
    }
  );

  const ecommerceInspirationConceptList =
    ecommerceInspirationConceptQuery.data
      ?.ecommerceInspirationConceptGenerationListFromAdmin?.edges;

  const items: IGalleryItem[] = React.useMemo(
    () =>
      ecommerceInspirationConceptList?.flatMap((generation) =>
        (generation.concepts ?? []).map((concept) => ({
          uid: concept.id,
          productUrl:
            concept.productReferenceMedia?.processedImage.image.medium.url,
          inspirationConceptUrl: concept.mediaGeneration.media?.medium.url,
        }))
      ) ?? [],
    [ecommerceInspirationConceptList]
  );

  const onFetchMoreInspirationConceptImages = () => {
    return ecommerceInspirationConceptQuery.fetchMore({
      variables: {
        cursor:
          ecommerceInspirationConceptQuery.data
            ?.ecommerceInspirationConceptGenerationListFromAdmin.cursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (fetchMoreResult == null) return prev;

        return produce(fetchMoreResult, (next) => {
          const existingIds = new Set(
            prev?.ecommerceInspirationConceptGenerationListFromAdmin?.edges.map(
              (edge) => edge.id
            ) ?? []
          );

          const newUniqueEdges = next.ecommerceInspirationConceptGenerationListFromAdmin!.edges.filter(
            (edge) => existingIds.has(edge.id) === false
          );

          next.ecommerceInspirationConceptGenerationListFromAdmin!.edges = [
            ...(prev?.ecommerceInspirationConceptGenerationListFromAdmin
              ?.edges ?? []),
            ...newUniqueEdges,
          ];
        });
      },
    });
  };

  async function handleMediaIdCopy(event: React.MouseEvent, mediaId: string) {
    event.preventDefault();

    if (navigator.clipboard == null) {
      alert('Media ID could NOT be copied');
      return;
    }

    await navigator.clipboard.writeText(mediaId);

    alert('Media ID copied to clipboard');
  }

  const [
    archiveUnarchiveInspirationConcept,
    archiveUnarchiveInspirationConceptMutation,
  ] = useMutation(
    gql`
      mutation ArchiveUnarchiveInspirationConcept(
        $ecommerceInspirationConceptId: ID!
      ) {
        ecommerceInspirationConceptArchiveAndUnArchive(
          input: {
            ecommerceInspirationConceptId: $ecommerceInspirationConceptId
          }
        ) {
          success
          ecommerceInspirationConcept {
            id
            archivedAt
          }
        }
      }
    `
  );

  return (
    <>
      <div className="images__container">
        <div className="filter__container">
          <AsyncSelect
            isMulti
            defaultOptions
            loadOptions={async (inputValue) => {
              try {
                const response = await apiFetch<{
                  results: { uid: string; name: string }[];
                }>(`/api/v2/partners/summary`, {
                  token: session.token,
                  query: {
                    limit: 300,
                    q: inputValue || undefined,
                    ecommercePartner: 'true',
                  },
                });

                return response.results.map((p) => ({
                  label: p.name,
                  value: p.uid,
                }));
              } catch (e) {
                alert(
                  'There was an error loading the partners, please try again'
                );
                return [];
              }
            }}
            styles={{
              control: (styles) => ({
                ...styles,
                minWidth: 300,
                width: 'auto',
                backgroundColor: 'white',
              }),
            }}
            placeholder="Ecommerce Partner"
            onChange={(selectedOptions: { value: string; label: string }[]) => {
              const selected = ((selectedOptions || []).map(
                (s) => s.value
              ) as unknown) as string[];

              setSelectedPartnerIds(selected ?? []);
            }}
          />
          <div
            style={{
              minWidth: 300,
              width: 'auto',
              backgroundColor: 'white',
              marginLeft: 16,
            }}
          >
            <select
              required
              className="custom-select"
              value={visibilityFilter}
              onChange={(e) => {
                const value = e.target.value as
                  | keyof typeof VISIBILITY_FILTER_OPTIONS
                  | 'VISIBLE';

                e.preventDefault();
                history.push({
                  ...location,
                  search: qs.stringify({
                    ...qs.parse(location.search),
                    visibilityFilter: value,
                  }),
                });
              }}
            >
              <option value="">Show all</option>
              {Object.entries(VISIBILITY_FILTER_OPTIONS).map(
                ([code, name]: [
                  code: keyof typeof VISIBILITY_FILTER_OPTIONS,
                  name: string
                ]) => (
                  <option key={code} value={code}>
                    {name}
                  </option>
                )
              )}
            </select>
          </div>
        </div>

        {ecommerceInspirationConceptQuery.loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <LoadingSpinnerCentered />
          </div>
        ) : ecommerceInspirationConceptList == null ||
          ecommerceInspirationConceptList.length === 0 ? (
          <div className="empty-state__container">
            <div className="empty-state__icon">
              <i className="fa fa-picture-o" aria-hidden="true"></i>
            </div>
            <div className="empty-state-text">
              <h4>No concepts found</h4>
              <p>
                There are no inspiration concepts available for this partner
              </p>
            </div>
          </div>
        ) : (
          ecommerceInspirationConceptList?.map((generation) => (
            <div
              key={generation.id}
              className="generation-item__list"
              style={{
                backgroundColor: 'white',
              }}
            >
              <div className="generation-item">
                {generation.concepts != null && (
                  <div className="generation-item__img-container">
                    <img
                      style={{
                        width: '100%',
                        aspectRatio: '1/1',
                        objectFit: 'cover',
                      }}
                      src={
                        generation.concepts[0].productReferenceMedia
                          .processedImage.image.medium.url
                      }
                      alt="reference"
                    />
                  </div>
                )}

                <div className="generation-item__controls">
                  <Tooltip label="Copy Media ID">
                    <button
                      type="button"
                      className={`btn btn-link pr-0 py-0  d-inline text-primary`}
                      onClick={(event) => {
                        if (generation.concepts == null) return;

                        handleMediaIdCopy(
                          event,
                          generation.concepts[0].productReferenceMedia
                            ?.processedImage?.image.id
                        );
                      }}
                    >
                      <i className="fa fa-copy" aria-hidden="true" />
                    </button>
                  </Tooltip>

                  {generation.concepts != null && (
                    <Tooltip label="This will ban/un-ban this product reference image from the inspiration concept generation">
                      <button
                        type="button"
                        style={{
                          cursor: 'pointer',
                          marginRight: 2,
                        }}
                        disabled={banProductReferenceImageMutation.loading}
                        className={`btn btn-link pr-0 py-0  d-inline ${
                          generation.concepts[0].productReferenceMedia
                            ?.ignoredFromInspirationGeneration ?? false
                            ? 'text-danger'
                            : 'text-muted'
                        }`}
                        onClick={() => {
                          if (generation.concepts == null) return;

                          banProductReferenceImage({
                            variables: {
                              productReferenceImageId:
                                generation.concepts[0].productReferenceMedia.id,
                            },
                          });
                        }}
                      >
                        <i className="fa fa-ban" aria-hidden="true" />
                      </button>
                    </Tooltip>
                  )}
                </div>
              </div>

              <div className="separator-line"></div>

              {(generation.concepts ?? []).map((concept) => (
                <div
                  key={concept.id}
                  className={`generation-item ${
                    concept.isAIPicked ? 'generation-item--winner' : ''
                  }`}
                >
                  <div
                    className="generation-item__img-container"
                    onClick={async () =>
                      history.replace({
                        ...location,
                        search: qs.stringify({
                          ...qs.parse(location.search),
                          selectedItemId: concept.id,
                        }),
                      })
                    }
                  >
                    <img
                      className="generation-item__base-image"
                      src={concept?.mediaGeneration.media?.medium?.url}
                      alt="candidate"
                    />
                    <img
                      className="generation-item__product-image"
                      src={
                        concept.productReferenceMedia.processedImage.image
                          .medium.url
                      }
                      alt="product"
                    />

                    {concept.isAIPicked === true && (
                      <div style={{ position: 'absolute', top: 5, right: 5 }}>
                        <span className="badge badge-success">Winner</span>
                      </div>
                    )}
                  </div>

                  <div className="generation-item__controls">
                    <Tooltip label="Copy Media ID">
                      <button
                        type="button"
                        className={`btn btn-link pr-0 py-0  d-inline text-primary`}
                        onClick={(event) =>
                          handleMediaIdCopy(
                            event,
                            concept.mediaGeneration.media.uid
                          )
                        }
                      >
                        <i className="fa fa-copy" aria-hidden="true" />
                      </button>
                    </Tooltip>

                    <button
                      type="button"
                      disabled={
                        archiveUnarchiveInspirationConceptMutation.loading
                      }
                      className={`btn btn-link pr-0 py-0  d-inline ${
                        concept.archivedAt == null
                          ? 'text__success'
                          : 'text__secondary'
                      }`}
                      onClick={() => {
                        archiveUnarchiveInspirationConcept({
                          variables: {
                            ecommerceInspirationConceptId: concept.id,
                          },
                        });
                      }}
                    >
                      <i
                        className={
                          concept.archivedAt == null
                            ? 'fa fa-eye'
                            : 'fa fa-eye-slash'
                        }
                      />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ))
        )}
      </div>

      <InView>
        {({ inView, ref }) => (
          <div ref={ref}>
            {inView && (
              <Component2
                didMount={() => onFetchMoreInspirationConceptImages()}
              />
            )}
          </div>
        )}
      </InView>

      <CustomGalleryModal
        selectedItemId={selectedItemId}
        items={items}
        onItemIdChange={({ itemId }) =>
          history.replace({
            ...location,
            search: qs.stringify({
              ...qs.parse(location.search),
              selectedItemId: itemId,
            }),
          })
        }
      />

      <style jsx>{`
        .filter__container {
          display: flex;
          flex-direction: row;
          justify-items: space-between;
          flex: 1;
          background-color: 'red';
        }
        .images__container {
          display: flex;
          flex-direction: column;
          justify-items: end;
          align-items: end;
          gap: 16px;
          padding: 0;
          width: 100%;
        }

        .generation-item__list {
          display: flex;
          justify-items: center;
          align-items: center;
          gap: 12px;
          width: 100%;
          padding: 12px;
        }

        .generation-item {
          position: relative;
          width: 350px;
          border: ${STANDARD_BORDER};
        }

        .generation-item--winner {
          border: 2px solid ${COLOR_PALLETTE.SUCCESS};
        }

        .text__success {
          color: ${COLOR_PALLETTE.SUCCESS};
        }

        .text__secondary {
          color: ${COLOR_PALLETTE.PRIMARY};
        }

        .generation-item__img-container {
          position: relative;
          width: 100%;
          aspect-ratio: 1/1;
        }

        .generation-item__base-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          aspect-ratio: 1/1;
          object-fit: cover;
          border: none;
        }

        .generation-item__product-image {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transform: translate(0, 5%) scale(45%);
        }

        .generation-item__controls {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-top: ${STANDARD_BORDER};
          height: 35px;
        }

        .generation-item__controls i {
          font-size: 18px;
        }

        .separator-line {
          min-width: 1px;
          width: 1px;
          flex: 0 0 1px;
          background-color: ${COLOR_PALLETTE.GRAY_2};
          margin: 0 4px;
          align-self: stretch;
          display: inline-block;
          height: 200px;
        }

        .empty-state__container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 60px 20px;
          background-color: #fff;
          border-radius: 8px;
          border: 1px solid ${COLOR_PALLETTE.GRAY_2};
          font-family: 'Nunito Sans';
          width: 100%;
        }

        .empty-state__icon {
          font-size: 48px;
          color: ${COLOR_PALLETTE.GRAY_2};
          margin-bottom: 20px;
        }

        .empty-state__icon i {
          border: 2px solid ${COLOR_PALLETTE.GRAY_2};
          border-radius: 50%;
          padding: 20px;
          background-color: white;
        }

        .empty-state-text {
          text-align: center;
          color: ${COLOR_PALLETTE.GRAY_4};
        }

        .empty-state-text h4 {
          margin-bottom: 10px;
          font-weight: 500;
          color: ${COLOR_PALLETTE.GRAY_5};
        }
      `}</style>
    </>
  );
}

export const AiEcommerceInspirationCenterJudgeGalleryRouter = ({
  session,
  match,
}) => (
  <BaseLayout session={session} match={match}>
    <JudgeGalleryRoute session={session} />
  </BaseLayout>
);
