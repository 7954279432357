import * as React from 'react';

import { gql, useQuery } from '../../../../components/ApolloClient';
import { useFetch } from '../../../../components/useFetch';
import { formatCredits, formatMoneyCents } from '../../../../utils';
import { isPartnerBooking } from '../selectors';

export function packageDescription({
  booking,
  duration,
  included,
  price,
  is_active,
  short_description,

  virtualTourProductType,
  virtualTourProductTier,
}: {
  booking;
  serviceType?: string;
  duration?: number;
  included?: number;
  price?: number;
  is_active?: boolean;
  short_description?: boolean;

  virtualTourProductType?: string;
  virtualTourProductTier?: string;
}) {
  const durationMessage =
    duration != null &&
    (duration <= 60
      ? `${duration} ${short_description === true ? 'min' : 'minutes'}`
      : duration === 60
      ? `${short_description === true ? '1 h' : '1 hour'}`
      : `${(duration / 60).toFixed(1)} ${
          short_description === true ? 'h' : 'hours'
        }`);

  const includedMessage =
    included != null && (included === -1 ? 'all photos' : `${included} photos`);

  const valueMessage =
    price == null
      ? false
      : isPartnerBooking({ booking })
      ? `${formatCredits(price)} credits`
      : `${formatMoneyCents(price, {
          currency: booking.currency || 'USD',
          ...(short_description === true && {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
        })}`;

  return (
    [
      durationMessage,

      (virtualTourProductType != null &&
        {
          SHOOT: 'photos only',
          VIRTUAL_TOUR: 'virtual tour only',
          VIRTUAL_TOUR_AND_PHOTOS: 'virtual tour and photos',
        }[virtualTourProductType]) ??
        false,

      (virtualTourProductTier != null &&
        {
          ALL_INCLUSIVE: 'all photos',
          STANDARD_FIDELITY: 'standard fidelity',
          HIGH_FIDELITY: 'high fidelity',
        }[virtualTourProductTier]) ??
        false,

      includedMessage,
      valueMessage,
    ]
      .filter((p) => p !== false)
      .join(', ') + (is_active ? '' : ' DEACTIVATED')
  );
}

const invalidPackage = ({
  booking,
  packages,
  serviceType,
  selectedShoottype,
}) =>
  booking.service_type === serviceType &&
  booking.shoottype === selectedShoottype &&
  !packages.find(({ uid }) => uid === booking.product_uid);

export function PackageDetails({
  booking,
  bookingClone,
  session,
  onServiceTypeChange,
  onPackageChange,
  onDetailsChange,
  onPropertySizeChange,
  bookingChangedKeys,
  hide = true,
}) {
  const shoottypesFetch = useFetch<{
    results?: { shoottype: string; display_name: string }[];
  }>({
    urlToFetch: `/api/v2/products/shoottypes`,
    queryToFetch: { service_type: bookingClone.service_type },
    session,
  });

  const packagesQuery = useQuery<
    {
      packages?: {
        edges?: {
          uid: string;
          serviceType?: 'photography' | 'videography';
          duration?: number;
          includedPhotos?: number;
          price?: number;
          isActive?: boolean;

          virtualTourProductType?: string;
          virtualTourProductTier?: string;
        }[];
      };
    },
    {
      regionId: string;
      shootType?: string;
      serviceType?: string;
      partnerId?: string;
      bookingId?: string;
    }
  >(
    gql`
      query BookingEditPackagesQuery(
        $regionId: ID!
        $shootType: String
        $serviceType: String
        $partnerId: ID
        $bookingId: ID
      ) {
        packages(
          regionId: $regionId
          serviceType: $serviceType
          shootType: $shootType
          partnerId: $partnerId
          bookingId: $bookingId
          customView: "ADMIN"
          sort: TYPE_TIER_DURATION
          first: 1000
        ) {
          edges {
            uid
            serviceType
            duration
            includedPhotos
            price
            isActive

            virtualTourProductType
            virtualTourProductTier
          }
        }
      }
    `,
    {
      variables: {
        regionId: bookingClone.region_uid,
        shootType: bookingClone.shoottype,
        serviceType: bookingClone.service_type,
        bookingId: bookingClone.uid,
        partnerId: bookingClone.partner_uid,
      },
      skip: bookingClone.region_uid == null,
    }
  );

  const spaceSize =
    bookingClone.property_size == null
      ? bookingClone.property_size
      : parseInt(bookingClone.property_size, 10);

  const packagesForPropertySizeQuery = useQuery<{
    packages?: {
      edges?: {
        uid: string;
        duration?: number;
        includedPhotos?: number;
        price?: number;
        isActive?: boolean;

        virtualTourProductType?: string;
        virtualTourProductTier?: string;
      }[];
    };
  }>(
    gql`
      query BookingEditPackagesForPropertySizeQuery(
        $regionId: ID!
        $shootType: String
        $spaceSize: Int!
      ) {
        packages(
          regionId: $regionId
          shootType: $shootType
          customView: "ADMIN"
          sort: TYPE_TIER_DURATION
          first: 1000
          spaceSize: $spaceSize
        ) {
          edges {
            uid
            duration
            includedPhotos
            price
            isActive

            virtualTourProductType
            virtualTourProductTier
          }
        }
      }
    `,
    {
      variables: {
        regionId: bookingClone.region_uid,
        shootType: bookingClone.shoottype,
        spaceSize,
      },
      skip: spaceSize == null || Number.isNaN(spaceSize),
    }
  );

  const packagesForPropertySizeSet = React.useMemo(
    () =>
      packagesForPropertySizeQuery.loading ||
      packagesForPropertySizeQuery.data?.packages?.edges == null
        ? null
        : new Set(
            packagesForPropertySizeQuery.data.packages.edges.map((p) => p.uid)
          ),
    [
      packagesForPropertySizeQuery.data?.packages?.edges,
      packagesForPropertySizeQuery.loading,
    ]
  );

  const packages = packagesQuery.data?.packages?.edges;

  const showDeactivatedPackages =
    booking.product_uid != null &&
    packages?.find((p) => p.uid === booking.product_uid)?.isActive === false;

  const addonsFetch = useFetch<{ results: { display_name: string }[] }>({
    urlToFetch: '/api/v2/products/addons',
    queryToFetch: { uids: (booking.addons ?? []).join(',') },
    session,
    disabled: (booking.addons ?? []).length === 0,
  });

  const addons = addonsFetch.result?.results;

  const addonsQuery = useQuery<{
    bookingById?: {
      id: string;
      addons?: { edges: { uid?: string; displayName?: string }[] };
    };
  }>(
    gql`
      query PackageDetailsGetNewAddons($bookingId: ID!) {
        bookingById(id: $bookingId) {
          id

          addons {
            edges {
              uid
              displayName
            }
          }
        }
      }
    `,
    {
      variables: { bookingId: booking.uid },
      skip: booking.uid == null,
    }
  );

  const newAddons = addonsQuery.data?.bookingById?.addons?.edges;

  return (
    <div
      className={'card-body flex-column' + (hide ? ' d-none' : ' d-flex')}
      style={{ minHeight: '350px' }}
    >
      {!packagesQuery.loading &&
        packages &&
        booking.product_uid &&
        invalidPackage({
          booking,
          packages,
          serviceType: bookingClone.service_type,
          selectedShoottype: bookingClone.shoottype,
        }) && (
          <div className="alert alert-warning">
            Warning: This booking is using an invalid package.
          </div>
        )}

      <div className="row" style={{ position: 'relative' }}>
        <div className="form-group col-12">
          <label>
            <small className="text-secondary">Service type</small>
          </label>

          <div className="row">
            <div className="col-12">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="serviceType"
                  id="serviceTypePhoto"
                  value="photography"
                  checked={bookingClone.service_type === 'photography'}
                  onChange={(e) =>
                    onServiceTypeChange({ service_type: e.target.value })
                  }
                />
                <label className="form-check-label" htmlFor="serviceTypePhoto">
                  Photography
                </label>
              </div>

              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="serviceType"
                  id="serviceTypeVideo"
                  value="videography"
                  checked={bookingClone.service_type === 'videography'}
                  onChange={(e) =>
                    onServiceTypeChange({ service_type: e.target.value })
                  }
                />
                <label className="form-check-label" htmlFor="serviceTypeVideo">
                  Videography
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={{ position: 'relative' }}>
        <div className="form-group col-4">
          <label>
            <small className="text-secondary">Shoot type</small>
          </label>

          <select
            className={`form-control ${
              bookingChangedKeys.shoottype ? 'is-valid' : ''
            }`}
            disabled={
              bookingClone.service_type == null ||
              bookingClone.region_uid == null ||
              shoottypesFetch.isPending
            }
            value={bookingClone.shoottype || ''}
            onChange={({ target: { value } }) =>
              onPackageChange({
                shoottype: value || null,
                product_uid: bookingClone.product_uid,
              })
            }
          >
            {shoottypesFetch.isPending && shoottypesFetch.result == null ? (
              <option value="">Loading...</option>
            ) : (
              <>
                <option value="">None</option>

                {shoottypesFetch.result?.results?.map((shoottype) => (
                  <option key={shoottype.shoottype} value={shoottype.shoottype}>
                    {shoottype.shoottype === 'premium-wedding'
                      ? 'Premium Wedding'
                      : shoottype.display_name}
                  </option>
                ))}
              </>
            )}
          </select>
        </div>

        <div className="form-group col-8">
          <label>
            <small className="text-secondary">Package</small>
          </label>

          <select
            className={`form-control ${
              bookingChangedKeys.product_uid ? 'is-valid' : ''
            }`}
            disabled={
              bookingClone.shoottype == null ||
              bookingClone.region_uid == null ||
              packagesQuery.loading
            }
            value={bookingClone.product_uid || ''}
            onChange={({ target: { value } }) => {
              const selectedPackage =
                value && packages?.find(({ uid }) => uid === value);
              onPackageChange({
                shoottype: bookingClone.shoottype,
                product_uid: value || null,
                duration: selectedPackage && selectedPackage.duration,
              });
            }}
          >
            {packagesQuery.loading && packagesQuery.data == null ? (
              <option value="">Loading...</option>
            ) : (
              <>
                <option value="">None</option>

                {packagesQuery.data?.packages?.edges
                  ?.filter(
                    (p) => p.isActive === true || showDeactivatedPackages
                  )
                  .map((packageItem) => (
                    <option key={packageItem.uid} value={packageItem.uid}>
                      {packagesForPropertySizeSet?.has(packageItem.uid) ===
                        true && '🟢 '}
                      {packageDescription({
                        booking: bookingClone,
                        serviceType: packageItem.serviceType,
                        duration: packageItem.duration,
                        included: packageItem.includedPhotos,
                        price: packageItem.price,
                        is_active: packageItem.isActive,

                        virtualTourProductType:
                          packageItem.virtualTourProductType,
                        virtualTourProductTier:
                          packageItem.virtualTourProductTier,
                      })}
                    </option>
                  ))}
              </>
            )}
          </select>
        </div>

        {!bookingClone.region_uid && (
          <div
            className={[
              'd-flex flex-columns',
              'justify-content-center align-items-center',
            ].join(' ')}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
            }}
          >
            <div className="alert alert-warning">
              First select a location, and save
            </div>
          </div>
        )}
      </div>

      {((newAddons != null && newAddons.length > 0) ||
        (booking.addons && booking.addons.length > 0)) && (
        <>
          <div className="mb-1">
            <small className="text-secondary">Addons</small>
          </div>

          {booking.addons && booking.addons.length > 0 && (
            <div className="row">
              <div className="form-group col">
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={
                    (addons &&
                      addons.map((addon) => addon.display_name).join(', ')) ||
                    ''
                  }
                  onClick={(ev) =>
                    // @ts-ignore
                    ev.target.select()
                  }
                />
              </div>
            </div>
          )}

          {newAddons != null && newAddons.length > 0 && (
            <div className="row">
              <div className="form-group col">
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={
                    (newAddons &&
                      newAddons.map((addon) => addon.displayName).join(', ')) ||
                    ''
                  }
                  onClick={(ev) =>
                    // @ts-ignore
                    ev.target.select()
                  }
                />
              </div>
            </div>
          )}
        </>
      )}

      {(bookingClone.property_size != null ||
        bookingClone.shoottype === 'realestate') && (
        <div className="form-group flex-1 d-flex flex-column">
          <label>
            <small className="text-secondary">
              Property size (square feet)
              {packagesForPropertySizeQuery.loading === false &&
                (packagesForPropertySizeQuery.data?.packages?.edges?.length ??
                  0) > 0 &&
                ` - 🟢 is the recommended package for this size`}
            </small>
          </label>

          <input
            className={`form-control flex-1 ${
              bookingChangedKeys.property_size ? 'is-valid' : ''
            }`}
            value={bookingClone.property_size || ''}
            onChange={({ target: { value } }) => {
              onPropertySizeChange({ value });
            }}
          />
        </div>
      )}

      <div className="form-group flex-1 d-flex flex-column">
        <label>
          <small className="text-secondary">Shoot details</small>
        </label>

        <textarea
          rows={3}
          className={`form-control flex-1 ${
            bookingChangedKeys.details ? 'is-valid' : ''
          }`}
          value={bookingClone.details || ''}
          onChange={({ target: { value } }) =>
            onDetailsChange({ details: value })
          }
        />
      </div>
    </div>
  );
}
