import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import InView from 'react-intersection-observer';
import Component2 from '@reach/component-component';
import AsyncSelect from 'react-select/async';

import BaseLayout from '../../components/BaseLayout';
import { LoadingSpinnerCentered } from '../../components/LoadingSpinner';
import { gql, useMutation, useQuery } from '../../components/ApolloClient';
import { COLOR_PALLETTE } from '../../constants';
import { STANDARD_BORDER } from '../editing-jobs/components';
import { Tooltip } from '../../components/Tooltip';
import { Modal } from '../../components/Modal';
import { KeyboardControlEvents } from '../../components/KeyboardControlEvents';
import { ImageLoadIndicator } from '../bookings/components/ImageLoadIndicator';
import { apiFetch } from '../../utils';

type IGalleryItem = {
  externalProcessedId: string;
  externalReferenceId: string;
  referenceImageUrl: string;
  processedImageUrl: string;
  referenceMediaId: string;
  processedMediaId: string;
};

function ImageGalleryModal({
  selectedItemId,
  items,
  onItemIdChange,
}: {
  selectedItemId: string | undefined;
  items: IGalleryItem[];
  onItemIdChange: (props: { itemId: string | undefined }) => void;
}) {
  if (items == null || items.length === 0 || selectedItemId == null) {
    return null;
  }

  const currentItemIndex = items.findIndex(
    (item) => item.externalProcessedId === selectedItemId
  );

  const prevItemIndex =
    currentItemIndex <= 0 ? items.length - 1 : currentItemIndex - 1;

  const nextItemIndex =
    currentItemIndex >= items.length - 1 ? 0 : currentItemIndex + 1;

  return (
    <Modal>
      <Component2
        didMount={() => {
          if (currentItemIndex !== -1) return;
          onItemIdChange({ itemId: undefined });
        }}
      />

      <div className="modal__overlay">
        <button
          className="modal__overlay-action"
          onClick={() => onItemIdChange({ itemId: undefined })}
        />

        <div
          className="item__list"
          style={{
            width: `${items.length * 100}vw`,
            transform: `translateX(-${currentItemIndex * 100}vw)`,
          }}
        >
          {items.map((item, itemIndex) => (
            <div key={item.externalProcessedId} className="item">
              {(itemIndex === currentItemIndex ||
                itemIndex === nextItemIndex ||
                itemIndex === prevItemIndex) && (
                <div className="item__img-container-row">
                  <ImageLoadIndicator src={item.referenceImageUrl}>
                    {({ loaded }) => (
                      <div className="item__img-container">
                        {!loaded && (
                          <div className="loading-spinner-container">
                            <i className="fa fa-spinner fa-spin fa-2x fa-fw text-light" />
                          </div>
                        )}
                        <img
                          className="item__img"
                          style={{ opacity: loaded ? 1 : 0 }}
                          height="auto"
                          src={item.referenceImageUrl}
                          alt="reference"
                        />
                      </div>
                    )}
                  </ImageLoadIndicator>

                  <ImageLoadIndicator src={item.processedImageUrl}>
                    {({ loaded }) => (
                      <div className="item__img-container">
                        {!loaded && (
                          <div className="loading-spinner-container">
                            <i className="fa fa-spinner fa-spin fa-2x fa-fw text-light" />
                          </div>
                        )}
                        <img
                          className="item__img"
                          style={{ opacity: loaded ? 1 : 0 }}
                          height="auto"
                          src={item.processedImageUrl}
                          alt="processed"
                        />
                      </div>
                    )}
                  </ImageLoadIndicator>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <KeyboardControlEvents
        left={() =>
          onItemIdChange({ itemId: items[prevItemIndex].externalProcessedId })
        }
        right={() =>
          onItemIdChange({ itemId: items[nextItemIndex].externalProcessedId })
        }
        esc={() => onItemIdChange({ itemId: undefined })}
      />

      <style jsx>{`
        .modal__overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          z-index: 100;
        }

        .modal__overlay-action {
          background-color: rgba(0, 0, 0, 0.5);
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: none;
          width: 100%;
          z-index: -1;
        }

        .item__list {
          height: 100%;
          display: flex;
          transition: transform 0.3s;
          pointer-events: none;
        }

        .item {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100vw;
        }

        .item__img-container-row {
          display: flex;
          justify-content: center;
          gap: 20px;
          max-width: 90%;
        }

        .item__img-container {
          position: relative;
          height: auto;
          width: 45%;
          max-height: 80vh;
        }

        .loading-spinner-container {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .item__img {
          object-fit: contain;
          pointer-events: auto;
          transition: opacity 1s;
          max-width: 100%;
        }
      `}</style>
    </Modal>
  );
}

function ExternalProcessedImagesGallery({
  session,
}: {
  session: { token: string };
}) {
  const history = useHistory();
  const location = useLocation();

  const [selectedPartnerIds, setSelectedPartnerIds] = React.useState<string[]>(
    []
  );

  // Parse URL parameters
  const urlParams = qs.parse(location.search);
  const selectedItemId = urlParams.selectedItemId as string | undefined;

  // Fetch processed images
  const ecommerceProcessedQuery = useQuery<{
    ecommerceExternalProcessedImagesListFromAdmin: {
      cursor: string;
      edges: {
        id: string;
        referenceImages: {
          edges: {
            id: string;
            image: {
              id: string;
              smallImageUrl: string;
            };
          }[];
        };
        processedImages: {
          edges: {
            id: string;
            referenceMediaId: string;
            image: {
              id: string;
              smallImageUrl: string;
            };
          }[];
        };
      }[];
    };
  }>(
    gql`
      query GetMediaGeneration(
        $cursor: String
        $first: Int
        $ecommercePartnerIds: [ID!]
      ) {
        ecommerceExternalProcessedImagesListFromAdmin(
          cursor: $cursor
          first: $first
          ecommercePartnerIds: $ecommercePartnerIds
        ) {
          cursor
          edges {
            id
            referenceImages {
              edges {
                id
                image {
                  id
                  smallImageUrl
                }
              }
            }
            processedImages {
              edges {
                id
                referenceMediaId
                image {
                  id
                  smallImageUrl
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        first: 20,
        ecommercePartnerIds:
          selectedPartnerIds.length > 0 ? selectedPartnerIds : null,
      },
    }
  );

  const [
    regenerateExternalProcessedImage,
    regenerateExternalProcessedImageMutation,
  ] = useMutation(
    gql`
      mutation RegenerateExternalProcessedImage(
        $externalProcessedMediaId: ID!
        $externalReferenceMediaId: ID!
      ) {
        ecommerceExternalProcessedImageRegenerateFromAdmin(
          input: {
            externalProcessedMediaId: $externalProcessedMediaId
            externalReferenceMediaId: $externalReferenceMediaId
          }
        ) {
          success
        }
      }
    `
  );

  // Create gallery items array for display
  const galleryItems: IGalleryItem[] = React.useMemo(() => {
    if (
      !ecommerceProcessedQuery.data
        ?.ecommerceExternalProcessedImagesListFromAdmin?.edges
    )
      return [];

    const items: IGalleryItem[] = [];

    // Process each edge to create paired items
    ecommerceProcessedQuery.data?.ecommerceExternalProcessedImagesListFromAdmin?.edges.forEach(
      (edge) => {
        console.log('Processing edge:', edge.id); // Debug log

        // For each processed image, find matching reference image
        if (edge.processedImages?.edges && edge.referenceImages?.edges) {
          edge.processedImages.edges.forEach((processedImage) => {
            // Skip if processedImage is missing critical data
            if (!processedImage || !processedImage.image) {
              console.log(
                'Skipping invalid processed image:',
                processedImage?.id
              );
              return;
            }

            // Find the matching reference image
            const referenceImageEdge = edge.referenceImages.edges.find(
              (refImg) => refImg?.id === processedImage.referenceMediaId
            );

            // Skip if reference image is missing or has invalid data
            if (
              !referenceImageEdge ||
              !referenceImageEdge.image ||
              !referenceImageEdge.image.smallImageUrl
            ) {
              console.log(
                'Missing reference image for processed image:',
                processedImage.id
              );
              return;
            }

            // Skip if processed image URL is missing
            if (!processedImage.image.smallImageUrl) {
              console.log(
                'Missing medium URL for processed image:',
                processedImage.id
              );
              return;
            }

            // Only add items with all required data
            items.push({
              externalProcessedId: processedImage.id,
              externalReferenceId: referenceImageEdge.id,
              referenceImageUrl: referenceImageEdge.image.smallImageUrl,
              processedImageUrl: processedImage.image.smallImageUrl,
              referenceMediaId: referenceImageEdge.image.id,
              processedMediaId: processedImage.image.id,
            });
          });
        }
      }
    );

    console.log('Created gallery items:', items.length); // Added logging
    return items;
  }, [
    ecommerceProcessedQuery.data?.ecommerceExternalProcessedImagesListFromAdmin
      ?.edges,
  ]);

  // Handle loading more images when scrolling
  const onFetchMoreImages = () => {
    console.log('Fetching more processed images');

    if (
      !ecommerceProcessedQuery.data
        ?.ecommerceExternalProcessedImagesListFromAdmin.cursor
    )
      return;

    return ecommerceProcessedQuery.fetchMore({
      variables: {
        cursor:
          ecommerceProcessedQuery.data
            .ecommerceExternalProcessedImagesListFromAdmin.cursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          ecommerceExternalProcessedImagesListFromAdmin: {
            ...fetchMoreResult.ecommerceExternalProcessedImagesListFromAdmin,
            edges: [
              ...(prev.ecommerceExternalProcessedImagesListFromAdmin?.edges ||
                []),
              ...fetchMoreResult.ecommerceExternalProcessedImagesListFromAdmin
                .edges,
            ],
          },
        };
      },
    });
  };

  // Copy media ID to clipboard
  async function handleMediaIdCopy(event: React.MouseEvent, mediaId: string) {
    event.preventDefault();

    if (navigator.clipboard == null) {
      alert('Media ID could NOT be copied');
      return;
    }

    await navigator.clipboard.writeText(mediaId);
    alert('Media ID copied to clipboard');
  }

  async function handleRegenerate(
    event: React.MouseEvent,
    externalProcessedId: string,
    externalReferenceId: string
  ) {
    event.preventDefault();

    await regenerateExternalProcessedImage({
      variables: {
        externalProcessedMediaId: externalProcessedId,
        externalReferenceMediaId: externalReferenceId,
      },
    });

    alert('Regenerate in progress, reload in a bit');
  }

  return (
    <>
      <div className="images__container">
        <div className="filter__container">
          <AsyncSelect
            isMulti
            defaultOptions
            loadOptions={async (inputValue) => {
              try {
                const response = await apiFetch<{
                  results: { uid: string; name: string }[];
                }>(`/api/v2/partners/summary`, {
                  token: session.token,
                  query: {
                    limit: 300,
                    q: inputValue || undefined,
                    ecommercePartner: 'true',
                  },
                });

                return response.results.map((p) => ({
                  label: p.name,
                  value: p.uid,
                }));
              } catch (e) {
                alert(
                  'There was an error loading the partners, please try again'
                );
                return [];
              }
            }}
            styles={{
              control: (styles) => ({
                ...styles,
                minWidth: 300,
                width: 'auto',
                backgroundColor: 'white',
              }),
            }}
            placeholder="Ecommerce Partner"
            onChange={(selectedOptions: { value: string; label: string }[]) => {
              const selected = ((selectedOptions || []).map(
                (s) => s.value
              ) as unknown) as string[];

              setSelectedPartnerIds(selected ?? []);
            }}
          />
        </div>

        {ecommerceProcessedQuery.loading === true &&
        galleryItems.length === 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <LoadingSpinnerCentered />
          </div>
        ) : galleryItems.length === 0 ? (
          <div className="empty-state__container">
            <div className="empty-state__icon">
              <i className="fa fa-picture-o" aria-hidden="true"></i>
            </div>
            <div className="empty-state-text">
              <h4>No processed images found</h4>
              <p>There are no external processed images available</p>
            </div>
          </div>
        ) : (
          <div className="gallery-grid">
            {galleryItems.map((item) => (
              <div key={item.externalProcessedId} className="gallery-item">
                <div className="gallery-item__images">
                  <div className="gallery-item__container">
                    <div className="gallery-item__img-container">
                      <img
                        src={item.referenceImageUrl}
                        alt="Reference"
                        className="gallery-image"
                      />
                      <div className="image-label">Reference</div>
                    </div>
                    <div className="gallery-item__controls">
                      <Tooltip label="Copy Media ID">
                        <button
                          type="button"
                          className="btn btn-link pr-0 py-0 d-inline text-primary"
                          onClick={(event) =>
                            handleMediaIdCopy(event, item.referenceMediaId)
                          }
                        >
                          <i className="fa fa-copy" aria-hidden="true" />
                        </button>
                      </Tooltip>
                    </div>
                  </div>

                  <div className="gallery-item__container">
                    <div className="gallery-item__img-container">
                      <img
                        src={item.processedImageUrl}
                        alt="Processed"
                        className="gallery-image"
                        onClick={() =>
                          history.replace({
                            ...location,
                            search: qs.stringify({
                              ...qs.parse(location.search),
                              selectedItemId: item.externalProcessedId,
                            }),
                          })
                        }
                      />
                      <div className="image-label">Processed</div>
                    </div>
                    <div className="gallery-item__controls">
                      <Tooltip label="Copy Media ID">
                        <button
                          type="button"
                          className="btn btn-link pr-0 py-0 d-inline text-primary"
                          onClick={(event) =>
                            handleMediaIdCopy(event, item.processedMediaId)
                          }
                        >
                          <i className="fa fa-copy" aria-hidden="true" />
                        </button>
                      </Tooltip>

                      <Tooltip label="Regenerate">
                        <button
                          type="button"
                          className="btn btn-link pr-0 py-0 d-inline text-primary"
                          disabled={
                            regenerateExternalProcessedImageMutation.loading
                          }
                          onClick={(event) =>
                            handleRegenerate(
                              event,
                              item.externalProcessedId,
                              item.externalReferenceId
                            )
                          }
                        >
                          <i className="fa fa-repeat" aria-hidden="true" />
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <InView>
        {({ inView, ref }) => (
          <div ref={ref}>
            {inView && !ecommerceProcessedQuery.loading && (
              <Component2 didMount={() => onFetchMoreImages()} />
            )}
          </div>
        )}
      </InView>

      <ImageGalleryModal
        selectedItemId={selectedItemId}
        items={galleryItems}
        onItemIdChange={({ itemId }) =>
          history.replace({
            ...location,
            search: qs.stringify({
              ...qs.parse(location.search),
              selectedItemId: itemId,
            }),
          })
        }
      />

      <style jsx>{`
        .images__container {
          display: flex;
          flex-direction: column;
          justify-items: end;
          align-items: end;
          gap: 16px;
          padding: 0;
          width: 100%;
        }

        .filter__container {
          display: flex;
          flex-direction: row;
          justify-items: space-between;
          flex: 1;
        }

        .gallery-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
          gap: 20px;
          width: 100%;
        }

        .gallery-item {
          border: ${STANDARD_BORDER};
          background-color: white;
          overflow: hidden;
          flex: 1;
          padding: 16;
          justify-content: center;
        }

        .gallery-item__images {
          display: flex;
          padding: 12px;
          gap: 10px;
        }

        .gallery-item__container {
          display: flex;
          border: ${STANDARD_BORDER};
          flex-direction: column;
          flex: 1;
        }

        .gallery-item__img-container {
          flex: 1;
          position: relative;
          aspect-ratio: 1/1;
          overflow: hidden;
        }

        .gallery-image {
          width: 100%;
          height: 100%;
          object-fit: contain;
          cursor: pointer;
          transition: transform 0.3s ease;
          background-color: #f5f5f5;
        }

        .gallery-image:hover {
          transform: scale(1.05);
        }

        .image-label {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.6);
          color: white;
          text-align: center;
          padding: 4px;
          font-size: 12px;
        }

        .gallery-item__controls {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-top: ${STANDARD_BORDER};
          height: 35px;
          padding: 0 10px;
          flex-direction: row;
        }

        .gallery-item__controls i {
          font-size: 18px;
        }

        .empty-state__container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 60px 20px;
          background-color: #fff;
          border-radius: 8px;
          border: 1px solid ${COLOR_PALLETTE.GRAY_2};
          font-family: 'Nunito Sans';
          width: 100%;
        }

        .empty-state__icon {
          font-size: 48px;
          color: ${COLOR_PALLETTE.GRAY_2};
          margin-bottom: 20px;
        }

        .empty-state__icon i {
          border: 2px solid ${COLOR_PALLETTE.GRAY_2};
          border-radius: 50%;
          padding: 20px;
          background-color: white;
        }

        .empty-state-text {
          text-align: center;
          color: ${COLOR_PALLETTE.GRAY_4};
        }

        .empty-state-text h4 {
          margin-bottom: 10px;
          font-weight: 500;
          color: ${COLOR_PALLETTE.GRAY_5};
        }

        .gallery-image-modal {
          width: 100%;
          height: 100%;
          object-fit: contain;
          max-height: 80vh;
          pointer-events: auto;
          transition: opacity 1s;
        }
      `}</style>
    </>
  );
}

export const ExternalProcessedImagesGalleryRouter = ({ session, match }) => (
  <BaseLayout session={session} match={match}>
    <ExternalProcessedImagesGallery session={session} />
  </BaseLayout>
);
