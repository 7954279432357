import React from 'react';
import Select from 'react-select';
import { useRouter } from 'next/router';
import BaseLayout from '../components/BaseLayout';
import ContentWrapper from '../components/ContentWrapper';
import { MetabaseEmbed } from '../components/MetabaseEmbed';
import { useFetch } from '../components/useFetch';
import { ensureString } from '../utils/strings';

export function AIFoodReportsRoute({ session, match }) {
  const router = useRouter();

  const report = ensureString(router.query.report);

  const { result } = useFetch<{ key: string; name: string }[]>({
    urlToFetch: `/api/v2/metabase-reports-resources`,
  });

  const reportsValueLabel = React.useMemo(
    () => result?.map((r) => ({ value: r.key, label: r.name })),
    [result]
  );

  return (
    <BaseLayout session={session} match={match}>
      <div className="tw-flex tw-justify-between tw-items-center">
        <h2>AI Operations Reports</h2>

        <Select
          value={reportsValueLabel?.find((r) => r.value === report)}
          options={reportsValueLabel}
          placeholder="Select a report"
          styles={{
            control: (provided) => ({
              ...provided,
              width: '300px',
            }),
          }}
          onChange={(selected) => {
            router.push({
              query: {
                ...router.query,
                report: selected?.value,
              },
            });
          }}
        />
      </div>

      <ContentWrapper className="tw-flex-1 mt-4 tw-flex tw-flex-col">
        <div className="tw-flex tw-flex-col tw-gap-4 tw-flex-1">
          {report == null ? null : <MetabaseEmbed report={report} />}
        </div>
      </ContentWrapper>
    </BaseLayout>
  );
}
