import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import moment from 'moment-timezone';

import { Modal } from './Modal';
import { locationForModal } from './ModalRoute';
import { gql, useMutation } from './ApolloClient';

export const BILLING_FREQUENCY = [
  { value: 'MONTHLY', text: 'Monthly' },
  { value: 'QUARTERLY', text: 'Quarterly' },
  { value: 'ANNUALLY', text: 'Annually (upfront)' },
];

const PAYMENT_TERMS = [
  { value: 'DAYS_0', text: '0 days' },
  { value: 'DAYS_7', text: '7 days' },
  { value: 'DAYS_15', text: '15 days' },
  { value: 'DAYS_30', text: '30 days' },
];

type EnterpriseContractProps = {
  partnerId: string;
  currency: string;
  session: { token: string };
  onDismiss: () => void;
  location;
  history;
  onContinue: () => void;
  modalParams: {
    modalName: string;
    type?: string;
    commitmentInUSD?: string;
    commitmentCreditsCents?: string;
    discountPercentage?: string;
    startAt?: string;
    durationInMonths?: string;
    referralCommissionsPercentage?: string;
    subscriptionPlan?: string;
    subscriptionPlanDiscountPercentage?: string;
    pdfUrl?: string;
    pdfFileName?: string;

    dateFirstInvoice?: string;
    billingFrequency?: string;
    paymentTerms?: string;
    aiMagic?: string;
    capture?: string;
    editingJobs?: string;
    planDiscount?: string;
  };
};

type FormValues = {
  dateFirstInvoice: string;
  billingFrequency: string;
  paymentTerms: string;
};

function validate(values) {
  const errors: {
    dateFirstInvoice?: string;
    billingFrequency?: string;
    paymentTerms?: string;
  } = {};

  if (values.dateFirstInvoice == null) {
    errors.dateFirstInvoice = 'Required';
  } else if (moment(values.dateFirstInvoice).isValid() === false) {
    errors.dateFirstInvoice = 'Invalid date';
  }

  const validBillingFrequencyValues = BILLING_FREQUENCY.map((frequency) =>
    frequency.value.toString()
  );
  if (values.billingFrequency == null) {
    errors.billingFrequency = 'Required';
  } else if (
    validBillingFrequencyValues.includes(values.billingFrequency) === false
  ) {
    errors.billingFrequency = 'Invalid billing frequency';
  }

  const validPaymentTermValues = PAYMENT_TERMS.map((term) =>
    term.value.toString()
  );
  if (values.paymentTerms == null) {
    errors.paymentTerms = 'Required';
  } else if (validPaymentTermValues.includes(values.paymentTerms) === false) {
    errors.paymentTerms = 'Invalid payment term';
  }

  return errors;
}

export const EnterpriseContractModalSecond = ({
  partnerId,
  currency,
  session,
  location,
  history,
  onDismiss,
  onContinue,
  modalParams,
}: EnterpriseContractProps) => {
  const [
    commitPartnerContractCreateAsAdmin,
    commitPartnerContractCreateAsAdminMutation,
  ] = useMutation<
    { data?: { contract?: { id: string } } },
    {
      partnerId: string;
      type: string;
      commitmentCreditsCents: number;
      discountPercentage: number;
      startAt: string;
      durationInMonths: string;
      referralCommissionsPercentage: number;
      subscriptionPlan: string;
      subscriptionPlanDiscountPercentage: number;
      pdfUrl: string;
      dateFirstInvoice: string;
      billingFrequency: string;
      paymentTerms: string;
    }
  >(
    gql`
      mutation NewPartnerContractCreateAsAdmin(
        $partnerId: ID!
        $type: String!
        $commitmentCreditsCents: Int!
        $discountPercentage: Float!
        $startAt: String!
        $durationInMonths: String!
        $referralCommissionsPercentage: Float
        $subscriptionPlan: String!
        $subscriptionPlanDiscountPercentage: Float!
        $pdfUrl: String!
        $dateFirstInvoice: String!
        $billingFrequency: String!
        $paymentTerms: String!
      ) {
        partnerContractCreateAsAdmin(
          input: {
            partnerId: $partnerId
            type: $type
            commitmentCreditsCents: $commitmentCreditsCents
            discountPercentage: $discountPercentage
            startAt: $startAt
            durationInMonths: $durationInMonths
            referralCommissionsPercentage: $referralCommissionsPercentage
            subscriptionPlan: $subscriptionPlan
            subscriptionPlanDiscountPercentage: $subscriptionPlanDiscountPercentage
            pdfUrl: $pdfUrl
            dateFirstInvoice: $dateFirstInvoice
            billingFrequency: $billingFrequency
            paymentTerms: $paymentTerms
          }
        ) {
          contract {
            id
          }
        }
      }
    `
  );

  const [
    commitPartnerContractProductBasedDiscountCreateAsAdmin,
    commitPartnerContractProductBasedDiscountCreateAsAdminMutation,
  ] = useMutation<
    { data?: { contract?: { id: string } } },
    {
      partnerId: string;
      type: string;
      commitmentInDollars: number;
      startAt: string;
      durationInMonths: string;
      referralCommissionsPercentage?: number;
      subscriptionPlan: string;
      pdfUrl: string;
      dateFirstInvoice: string;
      billingFrequency: string;
      paymentTerms: string;
      captureDiscount: number;
      magicAIDiscount: number;
      editingJobsDiscount: number;
      subscriptionDiscount: number;
    }
  >(
    gql`
      mutation partnerContractProductBasedDiscountCreateAsAdmin(
        $partnerId: ID!
        $type: String!
        $commitmentInDollars: Int!
        $startAt: String!
        $durationInMonths: String!
        $referralCommissionsPercentage: Float
        $subscriptionPlan: String!
        $pdfUrl: String!
        $dateFirstInvoice: String!
        $billingFrequency: String!
        $paymentTerms: String!
        $captureDiscount: Int!
        $magicAIDiscount: Int!
        $editingJobsDiscount: Int!
        $subscriptionDiscount: Int!
      ) {
        partnerContractProductBasedDiscountCreateAsAdmin(
          input: {
            partnerId: $partnerId
            type: $type
            commitmentInDollars: $commitmentInDollars
            startAt: $startAt
            durationInMonths: $durationInMonths
            referralCommissionsPercentage: $referralCommissionsPercentage
            subscriptionPlan: $subscriptionPlan
            pdfUrl: $pdfUrl
            dateFirstInvoice: $dateFirstInvoice
            billingFrequency: $billingFrequency
            paymentTerms: $paymentTerms
            captureDiscount: $captureDiscount
            magicAIDiscount: $magicAIDiscount
            editingJobsDiscount: $editingJobsDiscount
            subscriptionDiscount: $subscriptionDiscount
          }
        ) {
          contract {
            id
          }
        }
      }
    `
  );

  async function onSubmit(values) {
    const isProductBasedDiscount =
      modalParams.capture != null &&
      modalParams.editingJobs != null &&
      modalParams.planDiscount != null &&
      modalParams.aiMagic != null;

    // Common validations
    if (modalParams.type == null) {
      return window.alert('Invalid contract type');
    }

    if (modalParams.startAt == null) {
      return window.alert('Invalid contract start date');
    }

    if (modalParams.durationInMonths == null) {
      return window.alert('Invalid contract duration');
    }

    if (modalParams.subscriptionPlan == null) {
      return window.alert('Invalid subscription plan');
    }

    if (modalParams.pdfUrl == null) {
      return window.alert('Invalid pdf url');
    }

    if (modalParams.dateFirstInvoice == null) {
      return window.alert('Invalid first invoice date');
    }

    if (modalParams.billingFrequency == null) {
      return window.alert('Invalid billing frequency');
    }

    if (isProductBasedDiscount) {
      // Product-based discount validations + contract creation

      if (modalParams.commitmentInUSD == null) {
        return window.alert('Invalid commitment');
      }

      if (
        modalParams.capture == null ||
        modalParams.aiMagic == null ||
        modalParams.editingJobs == null ||
        modalParams.planDiscount == null
      ) {
        return window.alert('Invalid discount');
      }

      await commitPartnerContractProductBasedDiscountCreateAsAdmin({
        variables: {
          partnerId,
          type: modalParams.type,
          commitmentInDollars: parseInt(modalParams.commitmentInUSD) * 100,
          startAt: modalParams.startAt,
          durationInMonths: modalParams.durationInMonths,
          referralCommissionsPercentage:
            modalParams.referralCommissionsPercentage != null
              ? parseFloat(modalParams.referralCommissionsPercentage)
              : undefined,
          subscriptionPlan: modalParams.subscriptionPlan,
          pdfUrl: modalParams.pdfUrl,
          dateFirstInvoice: modalParams.dateFirstInvoice,
          billingFrequency: modalParams.billingFrequency,
          paymentTerms: values.paymentTerms,
          captureDiscount: parseInt(modalParams.capture),
          magicAIDiscount: parseInt(modalParams.aiMagic),
          editingJobsDiscount: parseInt(modalParams.editingJobs),
          subscriptionDiscount: parseInt(modalParams.planDiscount),
        },
      });
    } else {
      // Credit-based discount validations + contract creation
      if (modalParams.commitmentCreditsCents == null) {
        return window.alert('Invalid credit commitment');
      }

      if (modalParams.discountPercentage == null) {
        return window.alert('Invalid discount percentage');
      }

      if (modalParams.referralCommissionsPercentage == null) {
        return window.alert('Invalid referral comission percentage');
      }

      if (modalParams.subscriptionPlanDiscountPercentage == null) {
        return window.alert('Invalid subscription plan discount percentage');
      }

      await commitPartnerContractCreateAsAdmin({
        variables: {
          partnerId,
          type: modalParams.type,
          commitmentCreditsCents:
            parseInt(modalParams.commitmentCreditsCents) * 100,
          startAt: modalParams.startAt,
          durationInMonths: modalParams.durationInMonths,
          discountPercentage: parseFloat(modalParams.discountPercentage),
          referralCommissionsPercentage: parseFloat(
            modalParams.referralCommissionsPercentage
          ),
          subscriptionPlan: modalParams.subscriptionPlan,
          subscriptionPlanDiscountPercentage: parseFloat(
            modalParams.subscriptionPlanDiscountPercentage
          ),
          pdfUrl: modalParams.pdfUrl,
          dateFirstInvoice: modalParams.dateFirstInvoice,
          billingFrequency: modalParams.billingFrequency,
          paymentTerms: values.paymentTerms,
        },
      });
    }

    onContinue();
  }

  const initialValues: FormValues = {
    dateFirstInvoice: modalParams.dateFirstInvoice ?? '',
    billingFrequency: modalParams.billingFrequency ?? '',
    paymentTerms: modalParams.paymentTerms ?? PAYMENT_TERMS[0].value,
  };

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card my-4">
        <div className="card-header">
          <h4 className="mb-0">Create contract</h4>
        </div>

        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ isValid, touched, errors, handleChange }) => (
            <Form
              className="card-body d-flex flex-column"
              style={{ width: '1000px' }}
            >
              <div className="flex-1" style={{ position: 'relative' }}>
                <div className="row">
                  <div className="form-group col">
                    <label
                      className={
                        touched.dateFirstInvoice && errors.dateFirstInvoice
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Date of first invoice
                    </label>

                    <Field
                      name="dateFirstInvoice"
                      type="date"
                      className={`form-control ${
                        touched.dateFirstInvoice && errors.dateFirstInvoice
                          ? 'border-danger'
                          : ''
                      }`}
                      placeholder="e.g. 100,000"
                      onChange={(e) => {
                        handleChange(e);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              dateFirstInvoice: e.target.value,
                            },
                          })
                        );
                      }}
                    />
                    <ErrorMessage name="dateFirstInvoice">
                      {(msg) => <p className="text-danger mt-2 mb-0">{msg}</p>}
                    </ErrorMessage>
                  </div>

                  <div className="form-group col">
                    <label
                      className={
                        touched.billingFrequency && errors.billingFrequency
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Billing frequency
                    </label>

                    <Field
                      name="billingFrequency"
                      as="select"
                      className={`form-control ${
                        touched.billingFrequency && errors.billingFrequency
                          ? 'border-danger'
                          : ''
                      }`}
                      placeholder={`e.g. 90,000.00 ${currency}`}
                      onChange={(e) => {
                        handleChange(e);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              billingFrequency: e.target.value,
                            },
                          })
                        );
                      }}
                    >
                      <option value="">Select</option>
                      {BILLING_FREQUENCY.map((frequency) => (
                        <option key={frequency.value} value={frequency.value}>
                          {frequency.text}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="billingFrequency">
                      {(msg) => <p className="text-danger mt-2 mb-0">{msg}</p>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-6">
                    <label
                      className={
                        touched.paymentTerms && errors.paymentTerms
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Payment terms
                    </label>

                    <Field
                      name="paymentTerms"
                      as="select"
                      className={`form-control ${
                        touched.paymentTerms && errors.paymentTerms
                          ? 'border-danger'
                          : ''
                      }`}
                      onChange={(e) => {
                        handleChange(e);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              paymentTerms: e.target.value,
                            },
                          })
                        );
                      }}
                    >
                      <option value="">Select</option>
                      {PAYMENT_TERMS.map((term) => (
                        <option key={term.value} value={term.value}>
                          {term.text}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="paymentTerms">
                      {(msg) => <p className="text-danger mt-2 mb-0">{msg}</p>}
                    </ErrorMessage>
                  </div>
                </div>
              </div>

              {commitPartnerContractCreateAsAdminMutation.error?.message !=
                null && (
                <div className="row">
                  <p className="col text-danger mb-0">
                    {
                      commitPartnerContractCreateAsAdminMutation.error
                        ?.graphQLErrors[0].message
                    }
                  </p>
                </div>
              )}

              {commitPartnerContractProductBasedDiscountCreateAsAdminMutation
                .error?.message != null && (
                <div className="row">
                  <p className="col text-danger mb-0">
                    {
                      commitPartnerContractProductBasedDiscountCreateAsAdminMutation
                        .error?.graphQLErrors[0].message
                    }
                  </p>
                </div>
              )}

              <div className="d-flex justify-content-between mt-3">
                <button
                  type="submit"
                  className="btn btn-block btn-dark"
                  disabled={
                    isValid === false ||
                    commitPartnerContractCreateAsAdminMutation.loading ===
                      true ||
                    commitPartnerContractProductBasedDiscountCreateAsAdminMutation.loading ===
                      true
                  }
                >
                  Create contract
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <style jsx>{`
        label.text-secondary,
        label.text-danger {
          font-size: 0.8rem;
        }

        p.text-danger {
          font-size: 0.8rem;
        }
      `}</style>
    </Modal>
  );
};
